import { useTradingDocuments } from "api/trading-documents/hooks";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { correctionInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { Tab } from "api/trading-documents/models";
import { getSearch } from "../shared/utils/getSearch";
import { RightPanel } from "./rightPanel/RightPanel";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { NoResults } from "components/utils/noResults";
import { Loader } from "components/utils/loader";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ActionToolbar } from "../shared/components/actionToolbar/ActionToolbar";

export const CorrectionInvoicesList = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const type = "INVOICE";
  const invoiceType = "CORRECTION";
  const additionalListParams = {
    type,
    invoiceType,
  };
  const search = getSearch({ query, type, invoiceType, tab });
  const {
    data: correctionInvoices,
    error,
    isFetching,
    isLoading,
    pagination,
  } = useTradingDocuments(search);

  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  const noResults = !correctionInvoices.length && !isLoading && !error;

  if (error) {
    return (
      <div>
        <ListHeader additionalListParams={additionalListParams} tab={tab} />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader module="finances" />
        <ListHeader additionalListParams={additionalListParams} tab={tab} />
      </div>
    );
  }

  const selectMutlipleItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => {
    handleMultipleRowsHighlight(
      event,
      highlightedRows,
      id,
      index,
      setHighlightedRows,
      togglePanel,
      correctionInvoices,
    );
  };

  return (
    <div className="pageContainer">
      {isFetching && <Loader module="finances" />}
      <ListHeader additionalListParams={additionalListParams} tab={tab} />
      <RightPanelHandler name="correctionInvoices">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            {noResults ? (
              <NoResults on={noResults} subHeader="korekty" />
            ) : (
              <ModuleList>
                <ModuleListHeader
                  gridStyle={correctionInvoiceListConfig.grid}
                  labels={correctionInvoiceListConfig.labels}
                />
                <ModuleListBody>
                  {correctionInvoices.map((correctionInvoices, index) => (
                    <ListItem
                      correctionInvoice={correctionInvoices}
                      highlightedRows={highlightedRows}
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={correctionInvoices.id}
                      selectMutlipleItems={selectMutlipleItems}
                      togglePanel={togglePanel}
                    />
                  ))}
                </ModuleListBody>
              </ModuleList>
            )}
            <RightPanel close={close} />
            <InvoicePagination pagination={pagination} />
            {highlightedRows.length > 0 && (
              <ActionToolbar
                close={() => {
                  setHighlightedRows([]);
                }}
                quantity={highlightedRows.length}
                highlightedRows={highlightedRows}
                tradingDocument={{
                  type,
                  invoiceType,
                }}
              />
            )}
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};
